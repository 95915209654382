
import React, { Component } from 'react';



class About extends Component{
  render(props){
    return(
      <div>

        <h1>This is the category page {this.props.location.pathname}</h1>
        <p>Ita in philosophari. Duis ad do culpa ullamco eu do te efflorescere, quem nam
        nescius in cupidatat duis laboris, veniam commodo arbitror ne nam aute ullamco,
        pariatur hic ingeniis te qui aliqua fore ita incurreret. Admodum do ullamco in
        anim cupidatat quamquam, offendit quae legam offendit enim de summis non ab eram
        pariatur ubi in sed aute arbitror a labore laborum sed coniunctione aut non
        nulla fidelissimae et in quorum exquisitaque.A nulla fugiat do incurreret non
        aut admodum cohaerescant. Appellat de sunt, singulis ita magna. In esse quem
        malis laboris, o in velit constias ab esse singulis distinguantur ubi ea non
        multos ipsum quae.</p>
        <h1>This is the category page</h1>
      </div>

    );
  }

}
export default About;
